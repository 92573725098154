.login-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin: 0 auto;
  width: 55%;
  background-color: #d3d3d33b;
}
@media screen and (max-width: 1200px) {
  .login-div {
    margin-inline: 20%;
    width: 65%;
  }
}

@media screen and (max-width: 992px) {
  .login-div {
    margin-inline: 15%;
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .login-div {
    margin-inline: 10%;
    width: 85%;
  }
}

@media screen and (max-width: 576px) {
  .login-div {
    margin-inline: 5%;
    width: 90%;
  }
}
.p-inputgroup {
  width: auto;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-input {
  margin-bottom: 1.5rem;
}

.password-input {
  margin-left: 2rem;
}
.p-inputgroup-psw {
  width: 115%;
  display: flex;
  align-items: stretch;
}
.p-float-label input,
.p-float-label .p-inputtext,
.p-float-label .p-calendar {
  width: 100%;
}

.p-error {
  color: red;
  margin-top: 0.25rem;
  font-size: 0.875rem;
}

.p-invalid {
  border-color: red !important;
}

.p-field {
  margin-bottom: 1.5rem;
}
.password-reg {
  width: 115%;
}
.welcome-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.logout-btn,
.change-password-btn {
  border-radius: 10px;
}
@media screen and (min-width: 576px) {
  .welcome-div {
    flex-direction: column;
    justify-content: space-between;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    gap: 2rem;
  }

  .logout-btn {
    margin-right: 2rem;
  }
}
.welcome-message {
  display: inline;
}

@media screen and (max-width: 768px) {
  .welcome-message {
    display: block;
  }
}
