.account-div {
  margin: 2rem auto;
  max-width: 600px;
  text-align: center;
}

.account-form {
  margin-top: 1rem;
}

.account-info p {
  margin: 0.5rem 0;
}

.account-input {
  margin: 1rem 0;
}

.account-actions {
  margin-top: 1rem;
}

.p-button-primary {
  margin-right: 0.5rem;
}
