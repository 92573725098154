body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Personalizzazione del Toast per aumentarne la dimensione e centrarlo in alto */
.p-toast {
  top: 20px !important; /* Posiziona il Toast in alto */
  left: 50% !important; /* Centra il Toast orizzontalmente */
  transform: translateX(-50%) !important; /* Correzione per centrarlo esattamente */
  width: auto !important; /* Imposta una larghezza automatica */
  max-width: 500px; /* Limita la larghezza massima */
  z-index: 9999; /* Assicura che il Toast appaia sopra gli altri elementi */
}

.p-toast-message {
  padding: 1.5rem !important; /* Aumenta il padding per rendere il Toast più grande */
  font-size: 1.2rem !important; /* Ingrandisci il testo */
  word-wrap: break-word; /* Assicura che il testo vada a capo */
  text-align: center; /* Centra il testo nel Toast */
}

.p-toast-message .p-toast-summary {
  font-weight: bold; /* Rendi il titolo del Toast più visibile */
}

.p-toast-message .p-toast-detail {
  font-size: 1rem !important; /* Mantieni la dimensione del testo del dettaglio leggibile */
}

/* Aggiungi margine tra i Toast se ne vengono visualizzati più di uno */
.p-toast-top-center .p-toast-message {
  margin-top: 10px;
}

/* Media query per dispositivi mobili */
@media screen and (max-width: 768px) {
  .p-toast {
    width: 90% !important; /* Riduce la larghezza del Toast sui dispositivi mobili */
    max-width: 90% !important; /* Limita la larghezza massima per dispositivi mobili */
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .p-toast-message {
    padding: 1rem !important; /* Riduce il padding per adattarlo meglio sui dispositivi mobili */
    font-size: 1rem !important; /* Rendi il testo leggermente più piccolo per dispositivi mobili */
  }

  .p-toast-message .p-toast-detail {
    font-size: 0.9rem !important; /* Mantieni una dimensione del dettaglio più piccola e leggibile sui dispositivi mobili */
  }
}
