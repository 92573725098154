/* =========================
   Contenitore principale
   ========================= */
.appuntamenti-div {
  display: flex;
  flex-direction: column;
  align-items: center;      /* Centra orizzontalmente */
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 2rem;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* =========================
   Lista di appuntamenti
   ========================= */
.appointments-list {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

/* =========================
   Card del singolo appuntamento
   ========================= */
.app-card {
  margin: 1rem 0;
  width: 100%;
  max-width: none;
  padding: 1.5rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: #fff;
  box-sizing: border-box;
}

/* Righe interne */
.singolo-appuntamento-div {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* o left */
  gap: 0.5rem; /* Spaziatura tra icona e testo */
  margin-bottom: 0.5rem; /* mantieni pure questo se vuoi */
  font-size: 1rem;
}

/* Se hai un container pulsanti */
.singolo-appuntamento-btn-div {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

/* Bottoni di azione */
.elimina-btn {
  background-color: red;
  border-color: red;
}

.appuntamenti-btn {
  border-radius: 10px;
}

/* Dialog (PrimeReact) */
.responsive-dialog {
  width: 50vw !important;
}

@media screen and (max-width: 1200px) {
  .responsive-dialog {
    width: 60vw !important;
  }
}

@media screen and (max-width: 992px) {
  .responsive-dialog {
    width: 70vw !important;
  }
}

@media screen and (max-width: 768px) {
  .responsive-dialog {
    width: 80vw !important;
  }
}

@media screen and (max-width: 576px) {
  .responsive-dialog {
    width: 90vw !important;
  }
}

/* Icona "info" evidenziata */
.highlight-icon {
  color: red;
  font-size: 1.5em;
  transition: transform 0.2s ease;
}

.highlight-icon:hover {
  transform: scale(1.2);
}

/* Anteprima preparazione */
.preparation-preview {
  margin-left: 10px;
  font-size: 0.9em;
  color: #666;
  font-style: italic;
}

/* Messaggi, item generici ecc. */
.no-data-message,
.no-reports-message,
.error-message {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  margin: 2rem auto;
}

.no-data-message p,
.no-reports-message p,
.error-message {
  margin: 0.5rem 0;
}

.appointment-item,
.report-item {
  border: 1px solid #ddd;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* eventuali media query */
@media screen and (max-width: 768px) {
  .appuntamenti-div {
    padding: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .appuntamenti-div {
    padding: 0.5rem;
  }
  .app-card {
    width: 100% !important;
    padding: 0.8rem;
  }
}
