/* =========================
   Contenitore principale
   ========================= */
.referti-container {
  display: flex;
  flex-direction: column;
  align-items: center;      /* Centra orizzontalmente */
  margin: 0 auto;          /* Mantiene il blocco centrato */
  max-width: 1200px;       /* Limite massimo di larghezza */
  width: 100%;
  padding: 2rem;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* =========================
   Lista di referti
   ========================= */
.referti-list {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

/* =========================
   Card del singolo referto
   ========================= */
.referto-card {
  margin: 1rem 0;
  width: 100%;
  max-width: none;
  padding: 1.5rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: #fff;
  box-sizing: border-box;
}

/* Sovrascrivi la regola che fissava 300px */
.p-card {
  width: 100% !important;
  max-width: none !important;
}

/* Messaggi vari */
.no-data-message,
.no-reports-message,
.error-message {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  margin: 2rem 0;
}

.no-data-message p,
.no-reports-message p,
.error-message {
  margin: 0.5rem 0;
}

.appointment-item,
.report-item {
  border: 1px solid #ddd;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Esempio di pulsanti azione referti */
.action-buttons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.loading-message {
  text-align: center;
  margin: 2rem 0;
}

.singolo-referto-div {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Spaziatura fra icona e testo */
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.referti-btn-div {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem; /* un po' di spazio sotto */
}

/* Stile del "group box" (referto-group) se vuoi un bordo, sfondo, etc. */
.referto-group {
  margin: 1rem 0;
  /* Potresti aggiungere un border: 1px solid #ddd; 
     e un padding interno se vuoi sottolineare il gruppo. */
}

.appuntamenti-btn {
  border-radius: 10px;
}
.view-btn {
  background-color: #007ad9; /* blu */
  border-color: #007ad9;
}
.download-btn {
  background-color: green;
  border-color: green;
}
.download-all-btn {
  background-color: purple;
  border-color: purple;
}


