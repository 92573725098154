.registration-div {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.registration-form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f5f5f5; /* Colore di sfondo della pagina */
}

.registration-input {
  margin-bottom: 1.5rem;
}

.input-field {
  width: 100%;
}

.password-container {
  position: relative;
}

.password-container .p-password {
  width: 100%;
}

.dropdown-gender {
  width: 100%;
}

.submit-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.password-container .pi-eye,
.password-container .pi-eye-slash {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.error-field .p-inputtext, .error-field .p-password-input, .error-field .p-dropdown {
  border-color: red;
}
