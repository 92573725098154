.prenotazioni-div {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto;
  width: 55%;
  background-color: #d3d3d33b;
}
.dropdown {
  border-style: solid;
  border-color: rgba(43, 105, 221, 0.994);
  border-radius: 10px;
  border-width: 3%;
}
.seleziona-medico {
  margin-block: 2rem;
}
@media screen and (max-width: 1200px) {
  .prenotazioni-div {
    margin-inline: 20%;
    width: 65%;
  }
}

@media screen and (max-width: 992px) {
  .prenotazioni-div {
    margin-inline: 15%;
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .prenotazioni-div {
    margin-inline: 10%;
    width: 85%;
  }
}

@media screen and (max-width: 576px) {
  .prenotazioni-div {
    margin-inline: 5%;
    width: 90%;
  }
}

#buttondisplay > .p-inputtext {
  border-radius: 9px 0 0 9px;
}
.p-button {
  border-radius: 0 10px 10px 0;
}

.submit-btn {
  margin-top: 2rem;
  border-radius: 10px;
}

.switch-prenotazione {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  justify-content: flex-end;
}
.p-inputswitch {
  margin-left: 1rem;
}
.responsive-dialog {
  width: 50vw !important;
}

@media screen and (max-width: 1200px) {
  .responsive-dialog {
    width: 60vw !important;
  }
}

@media screen and (max-width: 992px) {
  .responsive-dialog {
    width: 70vw !important;
  }
}

@media screen and (max-width: 768px) {
  .responsive-dialog {
    width: 80vw !important;
  }
}

@media screen and (max-width: 576px) {
  .responsive-dialog {
    width: 90vw !important;
  }
}
