.tabmenu-div {
  margin-top: 3rem;
}
.tabmenu {
  margin: 0 auto;
  width: 55%;
  background-color: #d3d3d33b;
}

.back-icon {
  font-size: 1.5em;
  color: #007ad9;
  cursor: pointer;
  margin-left: 10rem !important;
}

.back-icon:hover {
  color: #005bb5;
}
@media screen and (max-width: 1200px) {
  .tabmenu {
    margin-inline: 20%;
    width: 65%;
  }
}

@media screen and (max-width: 992px) {
  .tabmenu {
    margin-inline: 15%;
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .tabmenu {
    margin-inline: 10%;
    width: 85%;
  }
}

@media screen and (max-width: 576px) {
  .tabmenu {
    margin-inline: 5%;
    width: 90%;
  }
}
